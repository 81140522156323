<template>
    
    <!-- Sign In Body -->
    <div class="main-body">
        <div class="auth-body-wrapper">
            <div class="auth-body center">
                <p class="auth-title">Sign In to your Account</p>
                <p class="auth-description">Access your account by entering your credentials below</p>
                <p class="auth-description">Don't have an account?
                    <span class="auth-text-btn">
                        <a v-if="redirectId==undefined" href="/sign-up"> Sign Up today</a>
                        <a v-if="redirectId=='checkout'" href="/sign-up?redirect=checkout"> Sign Up today</a>
                    </span>
                </p>

                <div class="auth-input-container">
                    <div class="auth-input-box">
                        <p class="input-label">Email address</p>
                        <input class="input-box" type="email" name="email" v-model="email">
                    </div>
                    <div class="auth-input-box">
                        <p class="input-label">
                            Password
                            <span class="forgot-password-button"><a href="/forgot-password"> Forgot Password?</a></span>
                        </p>
                        <input class="input-box" type="password" name="password" v-model="password">
                    </div>
                    <div class="btn sign-in-btn" @click="loginUser()">
                        <p v-if="isSubmitting" style="height: 27px;position: relative;"><svg class="center" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="28px" height="28px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" style="/* margin-top: 20px; */background: none;shape-rendering: auto;position: relative;width: 100%;text-align: center;height: 29px;/* top: 4px; */"><circle cx="50" cy="50" fill="none" stroke="#fff" stroke-width="8" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(274.643 50 50)"><animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform></circle></svg></p>
                        <p v-if="!isSubmitting" class="sign-in-text">Sign In</p>
                    </div>

                    <div class="or-text-wrapper">
                        <p class="or-text">OR</p>
                    </div>

                    <div class="google-login-button" @click="loginWithGoogle()">
                        <svg viewBox="0 0 48 48" class="google-logo" version="1.1" xmlns="http://www.w3.org/2000/svg"><g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g></svg>
                        <span class="login-continue-text">Continue with Google</span>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import { createUser } from '@/apis/authentication'
import { createUserInfo } from '@/apis/authentication'
import { checkUserExists } from '@/apis/authentication'
import { createUserOrders } from '@/apis/authentication'
import { createUserWithGoogle } from '@/apis/authentication'
import { loginUserWithEmailPassword } from '@/apis/authentication'

export default {
    name: 'SignIn',
    data(){
        return{
            email: undefined,
            password: undefined,
            isSubmitting: false,
            redirectId: undefined
        }
    },
    mounted(){
        this.checkUrlParams();
    },
    methods:{
        checkUrlParams(){
            var url_string = window.location.href;
            var url = new URL(url_string);
            this.redirectId = url.searchParams.get("redirect");
        },
        // Validate Email
        validateEmail(email) {
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        },
        // Login User using email and password
        async loginUser(){
            let self = this;
            if(!this.validateEmail(this.email)){
                this.$root.$refs['snackbar'].showToast("Not a valid email", "#e52627");
            }
            else if(this.password==undefined||this.password.length<6){
                this.$root.$refs['snackbar'].showToast("Invalid Password", "#e52627");
            }
            else{
                this.isSubmitting = true;
                var loginStatus = await loginUserWithEmailPassword(this.email, this.password);
                if(loginStatus["user"] != undefined){
                    this.$root.$refs['snackbar'].showToast("Login Successful", "#33a854");
                    this.isSubmitting = false;
                    setTimeout(function(){
                        if(self.redirectId == "updatePassword"){
                            window.location.href = "/account?ref=updatePassword";
                        }
                        else if(self.redirectId == "checkout"){
                            window.location.href = "/cart";
                        }
                        else{
                            window.location.href = "/";
                        }
                    }, 3000);
                }
                else{
                    this.isSubmitting = false;
                    var errorCode = loginStatus.code;
                    var errorMessage = loginStatus.message;
                    if(errorCode == "auth/wrong-password"){
                        this.$root.$refs['snackbar'].showToast(errorMessage, "#e52627");
                    }
                    else if(errorCode == "auth/user-not-found"){
                        this.$root.$refs['snackbar'].showToast("User not found!", "#e52627");
                    }
                    else{
                        this.$root.$refs['snackbar'].showToast("Something went wrong", "#e52627");
                    }
                }
            }
        },
        // FUnction to login user with Google Auth
        async loginWithGoogle(){
            let self = this;

            if(!this.isSubmitting){
                var loginStatus = await createUserWithGoogle();
                
                if(loginStatus["user"] != undefined){
                    var user = loginStatus["user"];
                    if(loginStatus["additionalUserInfo"]["isNewUser"]){
                        self.name = loginStatus["additionalUserInfo"]["profile"]["name"];

                        this.addToFirestore(user.uid, user.email, "google", true, true);
                    }
                    else{
                        this.$root.$refs['snackbar'].showToast("Login Successful", "#33a854");
                        this.isSubmitting = false;
                        setTimeout(function(){
                            if(self.redirectId == "checkout"){
                                window.location.href = "/cart";
                            }
                            else{
                                window.location.href = "/";
                            }
                        }, 3000);
                    }
                }
                else{
                    self.isSubmitting = false;
                    var errorCode = loginStatus.code;
                    var errorMessage = loginStatus.message;
                    if(errorCode == "auth/account-exists-with-different-credential"){
                        this.$root.$refs['snackbar'].showToast(errorMessage, "#e52627");
                    }
                    else{
                        this.$root.$refs['snackbar'].showToast("Something went wrong", "#e52627");
                    }
                }
            }
        },
        // Add User Info to Firestore
        addUserInfo(uid, email, isVerified, isSubscribed){
            var data = {
                "userId": uid,
                "email": email,
                "isVerified": isVerified,
                "isSubscribed": isSubscribed
            }
            createUserInfo(uid, data);
        },
        // Add new user data to firestore
        async addToFirestore(uid, email, loginMethod, isVerified, isSubscribed){
            let self = this;

            var checkUser = await checkUserExists(uid);

            if(!checkUser){
                this.addUserInfo(uid, email, isVerified, isSubscribed);

                let data = {
                    "userId": uid,
                    "email": email,
                    "timestamp": Math.floor(Date.now()/1000),
                    "loginMethod": loginMethod,
                    "coupons": []
                }

                var status = await createUser(uid, data);
                if(status){
                    var userOrderStatus = await createUserOrders(uid, {orders: []});
                    if(userOrderStatus){
                        if(loginMethod=="email-password"){
                            this.$root.$refs['snackbar'].showToast("We have sent an email to " + self.email + " for verification. Please check your inbox", "#33a854");
                        }
                        else{
                            this.$root.$refs['snackbar'].showToast("Sign Up Successful. Redirecting..", "#33a854");
                            setTimeout(function(){
                                window.location.href = "/";
                            }, 3000);
                        }
                    }
                }
            }
            else{
                this.$root.$refs['snackbar'].showToast("Sign Up Successful. Redirecting..", "#33a854");
                setTimeout(function(){
                    window.location.href = "/";
                }, 3000);
            }
            this.isSubmitting = false;
        },
    }
}
</script>

<style scoped>
    .main-body{max-width: 1280px;margin: auto;margin-bottom: 48px;}
    .auth-body-wrapper{position: relative;padding: 0 12px;width: 100%;min-height: calc(100vh - 105px);}
    .auth-title{font-size: 20px;font-weight: 700;padding: 4px 0;display: inline-block;line-height: 24px;margin-bottom: 8px;}
    .auth-description{font-size: 15px;line-height: 1.4;font-weight: 400;color: #868686;}
    .auth-text-btn{margin: 0px;margin-left: 4px;line-height: 24px;font-size: 14px;font-weight: 600;color: #3e50b5;-webkit-user-select: none;-moz-user-select: none;-ms-user-select: none;user-select: none;padding: 4px 0;cursor: pointer;}

    .auth-input-container{margin: 16px 0px;}
    .auth-input-box{margin-bottom: 4px;}
    .auth-input-box input-box{padding: 9px 12px;}
    .input-label{padding: 8px 4px;padding-bottom: 4px;font-size: 14px;font-weight: 500;}
    .forgot-password-button{float: right;color: #6366f1;font-size: 12px;padding: 2px 0;font-weight: 600;cursor: pointer;}
    .sign-in-btn{margin: 0px;min-width: 120px;width: 100%;margin-top: 12px;padding: 7px 16px;line-height: 27px;}
    .sign-in-text{font-size: 15px;}

    .or-text-wrapper{display: flex;box-sizing: border-box;border-top: 1px solid #e0e0e0;min-width: 0;min-height: 0;flex-direction: row;justify-content: center;margin: 24px 0 12px 0;}
    .or-text{font-size: 14px;position: relative;top: -10px;background: #fff;padding: 0 24px;margin: 0;color: #3a3a3a;font-weight: 500;}

    .google-login-button{border: 1px solid #dadce0;display: inline-block;width: 100%;padding: 12px;border-radius: 8px;cursor: pointer;margin: 0;text-align: center;}
    .google-logo{width: 24px;height: 24px;float: left;}
    .login-continue-text{line-height: 24px;}

    /* Tablet */
    @media only screen and (max-width: 1024px){
        .main-body{grid-template-columns: 1fr;margin: 0;}
        .auth-body-wrapper{margin-left: unset;max-width: unset;min-height: 500px;}
        .auth-body{margin: 0;position: absolute;left: 50%;top: 50%;transform: translate(-50%, -50%);}
    }

    /* Phone */
    @media only screen and (max-width: 600px){
        .auth-body{width: 90%;}
        .auth-body-wrapper{height: calc(100vh - 105px);}
    }
</style>